import React, { useContext, useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import styled, { css } from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { Modal } from "notes";
import { RegistrationForm } from "./";
import { VideoModal } from "Components";
import { Layout } from "../Layout";
import { fonts } from "theme/fonts";
import { Duration } from "luxon";
import {
  TimeContext,
  EventContext,
  CountdownSmall as Countdown,
  SSOButton,
  TextReminder,
  AnalyticsContext,
} from "Components";
import playIcon from "Images/play-button.png";
import { PreCampaign } from "../PreCampaign";
import { useMediaQuery } from "Components/Hooks/useMediaQuery";

export const RegisterLayout = React.memo(
  ({ onSuccess, postCampaign, registered, preCampaign }) => {
    const { time } = useContext(TimeContext);
    const { event } = useContext(EventContext);
    const { logClick, logVideoAction, logLeadSubmission } = useContext(
      AnalyticsContext
    );
    const [cookies, setCookie] = useCookies();
    const isMobile = useMediaQuery();

    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({});
    const [video, setVideo] = useState(null);

    const [eventFired, setEventFired] = useState(false);
    const params = new URLSearchParams(window.location.search);
    const utm_source = params.get("utm_source");
    useEffect(() => {
      if (utm_source === "oauth" && !eventFired) {
        console.log("Firing form submit");
        setEventFired(true);
        logLeadSubmission({ formPart: 0 });
      }
    }, []);

    let RegFormContent;
    let RegFormContentMobile;

    const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
    const storageDirectory = event.storageDirectory;

    let regBg = `${api}/o/${storageDirectory}%2F${event?.backgrounds?.registration}?alt=media`;
    let headerLogo = `${api}/o/${storageDirectory}%2F${event?.logos?.header?.path}?alt=media`;
    let desktopImage = `${api}/o/${storageDirectory}%2F${event?.register?.artistImageDesktop?.path}?alt=media`;
    let mobileImage = `${api}/o/${storageDirectory}%2F${event?.register?.artistImageMobile?.path}?alt=media`;
    let postCampaignBg = `${api}/o/${storageDirectory}%2F${event?.backgrounds?.postCampaign}?alt=media`;
    let postCampaignBgMob = `${api}/o/${storageDirectory}%2F${event?.backgrounds?.postCampaignMob}?alt=media`;
    let patternTop = `${api}/o/${storageDirectory}%2FpatternTop.png?alt=media`;
    let patternArtist = `${api}/o/${storageDirectory}%2FpatternArtist.png?alt=media`;

    const { heading, eventTime, button } = event.register.content.part1;

    const headerLogoLink = event.logos.header.link;

    const startDate = event.start.toFormat("LLLL d");

    const logoClickHandler = () => {
      logClick(
        {
          label: "Header Logo",
          url: headerLogoLink,
        },
        "header"
      );
    };

    const linkClickHandler = (url, label, category) => {
      logClick({ label, url }, category);
    };

    const handleSuccess = (admin, token, formPart, email) => {
      onSuccess(admin, token, formPart);
      if (formPart === 1) {
        setCookie("email", email, { maxAge: 31622400 });
        setFormData({ email });
      }
    };

    const setModalVisible = (bool, video) => {
      setVideo(video);
      setOpen(bool);
      logVideoAction({
        bool,
        videoLink: video,
        videoTitle: "Hero Video",
      });
    };

    if (postCampaign) {
      RegFormContent = (
        <>
          <ClosedHeading isMobile={isMobile}>
            {ReactHtmlParser(event.postShow.closedHeading)}
          </ClosedHeading>
          <ClosedBody isMobile={isMobile}>
            {ReactHtmlParser(event.postShow.closedBody)}
          </ClosedBody>
          <EventInfo>
            <EventDate>[DATE TBD]</EventDate>
            <EventTime>{ReactHtmlParser(eventTime)}</EventTime>
          </EventInfo>
          <SSOButton buttonText={button} />
          <Login isMobile={isMobile}>
            Already have an AARP.org account?{" "}
            <StyledSSOButton buttonText="Log in" isLogin /> to your account to
            RSVP.
          </Login>
          <Blurb isMobile={isMobile}>
            This event is free, but you must RSVP in advance.
            <br />
            <StyledSSOButton buttonText="Log in" isLogin /> to your AARP.org
            account or <StyledSSOButton buttonText="register" /> for AARP.org
            today to RSVP.
            <br />
            AARP membership is not required.
          </Blurb>
        </>
      );
    } else if (cookies?.formPart == 2 || registered) {
      const {
        heading,
        subHeading,
        body,
        countdownHeading,
      } = event.register.content.part2;

      const timeToStart =
        time < event.start &&
        Duration.fromMillis(event.start - time).shiftTo(
          "days",
          "hours",
          "minutes",
          "seconds",
          "milliseconds"
        );

      const timer = {
        label: countdownHeading,
        days: timeToStart.days,
        hours: timeToStart.hours,
        minutes: timeToStart.minutes,
        seconds: timeToStart.seconds,
      };

      RegFormContent = (
        <>
          {heading && (
            <Heading isMobile={isMobile}>{ReactHtmlParser(heading)}</Heading>
          )}
          {subHeading && (
            <SubHeading isMobile={isMobile}>
              {ReactHtmlParser(subHeading)}
            </SubHeading>
          )}
          {body && (
            <Body isMobile={isMobile} registered={true}>
              {ReactHtmlParser(body)}
            </Body>
          )}
          <SmallCountdown isMobile={isMobile}>
            <Countdown {...timer} />
          </SmallCountdown>
          <TextReminder buttonText={"Text Reminder"} />
        </>
      );
    } else if (!isMobile) {
      const { artistHeading, eventTime, button } = event.register.content.part1;

      RegFormContent = (
        <>
          {heading && (
            <Heading isMobile={isMobile}>{ReactHtmlParser(heading)}</Heading>
          )}
          <SubHeading isMobile={isMobile}>
            {ReactHtmlParser(artistHeading)}
          </SubHeading>
          <EventInfo>
            <EventDate>
              {startDate}
              <sup>th</sup>
            </EventDate>
            <EventTime>{ReactHtmlParser(eventTime)}</EventTime>
          </EventInfo>
          <SSOButton buttonText={button} />
          <Login isMobile={isMobile}>
            Already have an AARP.org account?{" "}
            <StyledSSOButton buttonText="Log in" isLogin /> to your account to
            RSVP.
          </Login>
          <Blurb isMobile={isMobile}>
            This event is free, but you must RSVP in advance.
            <br />
            <StyledSSOButton buttonText="Log in" isLogin /> to your AARP.org
            account or <StyledSSOButton buttonText="register" /> for AARP.org
            today to RSVP.
            <br />
            AARP membership is not required.
          </Blurb>
        </>
      );
    }

    if (isMobile) {
      const { artistHeading, eventTime, button } = event.register.content.part1;

      RegFormContentMobile = (
        <>
          <Heading isMobile={isMobile} style={{ marginBottom: "-10px" }}>
            {ReactHtmlParser(artistHeading)}
          </Heading>
          <EventInfo>
            <EventDate>
              {startDate}
              <sup>th</sup>
            </EventDate>
            <EventTime>{ReactHtmlParser(eventTime)}</EventTime>
          </EventInfo>
          <SSOButton buttonText={button} />
          <Login isMobile={isMobile}>
            Already have an AARP.org account?{" "}
            <StyledSSOButton buttonText="Log in" isLogin /> to your account to
            RSVP.
          </Login>
          <Blurb isMobile={isMobile}>
            This event is free, but you must RSVP in advance.
            <br />
            <StyledSSOButton buttonText="Log in" isLogin /> to your AARP.org
            account or <StyledSSOButton buttonText="register" /> for AARP.org
            today to RSVP.
            <br />
            AARP membership is not required.
          </Blurb>
        </>
      );
    }
    return (
      <Layout
        registration
        showHeader
        heroContent={false}
        noPadding
        showFooter
        showFooterTop
        registered={registered}
        preCampaign={preCampaign}
        expandFooterTop={postCampaign}
      >
        <Registration formPart={cookies.formPart}>
          {/* {!isMobile && <TopPatternImg src={patternTop} alt="" />}
          {!isMobile && (
            <BackgroundPattern>
              <ArtistPatternImg
                src={postCampaign ? postCampaignBg : patternArtist}
                alt="aarp brain health wave pattern"
              />
            </BackgroundPattern>
          )} */}
          {/* {postCampaign && isMobile && (
            <BackgroundPatternPostCamp postCampaign>
              <img
                src={postCampaignBgMob}
                alt="aarp brain health wave pattern"
              />
            </BackgroundPatternPostCamp>
          )} */}
          <Content
            as="section"
            id="registration-content"
            postCampaign={postCampaign}
          >
            <ContentContainer
              isRegistered={registered && !isMobile}
              isMobilePostCampaign={isMobile && postCampaign}
              isMobile={isMobile}
            >
              {/* <Link
                href={headerLogoLink}
                target="_blank"
                rel="noopener noreferrer"
                onClick={logoClickHandler}
              >
                <Logo src={headerLogo} />
              </Link> */}
              {isMobile && !registered && !postCampaign && (
                <>
                  <Heading isMobile={isMobile}>
                    {ReactHtmlParser(heading)}
                  </Heading>
                  <SSOButton
                    buttonText={"RSVP HERE"}
                    style={{ margin: "10px auto -50px 0" }}
                  />
                </>
              )}
              <FormContent>{RegFormContent}</FormContent>
            </ContentContainer>
          </Content>

          <ArtistImage
            isMobile={isMobile}
            as="section"
            id="artist-image"
            postCampaign={postCampaign}
          >
            <img
              src={isMobile ? mobileImage : desktopImage}
              alt="artist sara evans"
            />
          </ArtistImage>

          {isMobile && !registered && !postCampaign && (
            <Content as="section">
              <ContentContainer isMobile={isMobile}>
                <FormContent>{RegFormContentMobile}</FormContent>
              </ContentContainer>
            </Content>
          )}
        </Registration>
      </Layout>
    );
  }
);

// ======== CONTENT / LEFT SIDE ======== //

const Blurb = styled.h3`
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  color: #000;
  span {
    font-size: 18px;
    font-weight: 600;
  }
  ${({ isMobile }) =>
    isMobile &&
    `
  font-size: min(3.5vw, 16px);
  `}
`;

const StyledSSOButton = styled(SSOButton)`
  margin-top: 16px;
  background-color: inherit;
  color: #ea1300;
  text-decoration: underline;
  border-radius: inherit;
  padding: inherit;
  cursor: pointer;
  text-transform: none;
  font-size: inherit;
  font-weight: inherit;
  &:hover,
  &:focus {
    background-color: inherit;
  }
`;

const Registration = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  width: 100%;

  @media ${(props) => props.theme.maxMedia.medium} {
    flex-direction: row;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  background-color: transparent;
  @media ${(props) => props.theme.maxMedia.medium} {
    background-color: #ffeee6;
  }
  ${({ postCampaign }) =>
    postCampaign &&
    css`
      background-color: #ffeee6 !important;
    `}
`;
const ContentContainer = styled.div`
  margin: 0 auto;
  padding: 35px 35px 105px 35px;
  margin: 50px 55px 100px 55px;
  width: 38vw;
  max-width: 700px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 16px;
  z-index: 50;
  background-color: #fff;
  box-shadow: 1px 1px 15px 5px #99999970;
  ${({ isMobile }) =>
    isMobile &&
    `
      padding: 5px 35px 35px 35px;

  width: 95%;
  margin: 20px 0px;
  border-radius: 0px;
  z-index: unset;
  box-shadow: none;
  background-color: transparent;

  `}
   ${({ isRegistered }) =>
     isRegistered &&
     `
      margin: 50px 55px 170px 55px;
      `}
    ${({ isMobilePostCampaign }) =>
      isMobilePostCampaign &&
      `
      width: 80%;
      margin: 20px;
    
  margin: 40px 30px 170px 30px;
  border-radius: 16px;
    z-index: 50;
    box-shadow: 1px 1px 15px 5px #99999970;
  background-color: #fff;
  padding: 35px 35px 35px 35px;

  `}
  /* @media ${(props) => props.theme.maxMedia.medium} {
    background-color: #fff;
    max-width: unset;
    padding: 25px 0 25px 50px;
    max-width: 520px;
    min-height: 700px;
  } */
`;
const TopPatternImg = styled.img`
  width: 100%;
  object-fit: cover;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
`;
const ArtistPatternImg = styled.img`
  width: 100%;
  object-fit: cover;
`;
const BackgroundPattern = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  bottom: 0;
  width: 100%;
  /* height: 100%; */
  overflow: hidden;

  ${({ postCampaign }) =>
    postCampaign &&
    css`
      top: unset;
      bottom: 0;
    `}
`;
const BackgroundPatternPostCamp = styled.div`
 // background: url(${(props) => props.src});
  position: absolute;
  display: flex;
  flex-direction: row;
  background-repeat: repeat-y;
  width: 100%;
  bottom: 0;
  left: 0;
  /* height: 100%; */
  overflow: hidden;
  img {
  width: 100%;
  object-fit: fill;
  margin: auto 0 -60px 0px;
  }

  ${({ postCampaign }) =>
    postCampaign &&
    css`
      top: unset;
      bottom: 0;
    `}
`;
const Link = styled.a`
  margin: 25px 0;
  display: block;
  width: 50%;
`;

const Logo = styled.img`
  width: 100%;
  max-width: 180px;
`;

const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  background-color: #fff;
`;

const Heading = styled.h2`
  font-size: min(2.5vw, 40px);
  line-height: 1.2;
  //  margin-bottom: 24px;
  color: #ea1300;

  span {
    font-weight: 300;
    @media ${(props) => props.theme.maxMedia.small} {
      font-size: 25px;
      display: block;
    }
  }
  ${({ isMobile }) =>
    isMobile &&
    `
 font-size: min(7vw, 28px);
  `}
`;
const ClosedHeading = styled(Heading)`
  margin-top: 7%;
  ${({ isMobile }) =>
    isMobile &&
    `
    margin-top: 0;
  font-size: min(7vw, 28px);
  `}
`;

const SubHeading = styled.h3`
  font-size: 22px;
  line-height: 1.2;
  margin-bottom: 24px;
  font-weight: 400;
  color: #000;
  span {
    white-space: nowrap;
  }
`;

const Body = styled.p`
  font-weight: 400;
  font-size: min(1.2vw, 24px);
  line-height: 130%;
  margin: 0;
  ${({ registered }) => registered && "margin: 0 0 15px 0;"}
  a,
  a:active,
  a:visited {
    color: #ea1300;
  }
  ${({ isMobile }) =>
    isMobile &&
    `
  font-size: min(4vw, 18px);
  `}
`;

const ClosedBody = styled(Body)`
  ${({ isMobile }) =>
    isMobile &&
    `
  font-size: min(4vw, 18px);
  `}
`;

const SmallCountdown = styled.div`
  width: 100%;
  margin-bottom: 24px;

  @media ${(props) => props.theme.maxMedia.medium} {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    width: unset;
    padding: 30px 25px 0 0;
    margin: 0;
  }
`;

const EventInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-size: clamp(16px, 6vw, 32px);
  font-weight: 600;
  width: 100%;
  line-height: 1.15;
  margin: 20px 0;

  @media ${(props) => props.theme.maxMedia.medium} {
    font-size: clamp(16px, 8vw, 24px);
  }
  @media (min-width: 950px) {
    flex-direction: row;
  }
`;

const EventDate = styled.div`
  padding: 0 15px 0 0;
`;

const EventTime = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  @media (min-width: 950px) {
    padding-top: 0px;
    border-left: 2px solid red;
    padding-left: 15px;
  }
`;

const Login = styled.p`
  a {
    color: #f11a00;
  }
  ${({ isMobile }) =>
    isMobile &&
    `
  font-size: min(3.5vw, 16px);
  margin-top: 20px;
  `}
`;

// ======== ARTIST IMAGE / RIGHT SIDE ======== //

const ArtistImage = styled.div`
  position: relative;
  display: flex;
  cursor: pointer;
  justify-content: flex-end;
  align-items: stretch;
  width: 100%;
  background-color: #ffeee6;

  img {
    object-fit: cover;
    width: 100%;
    /* max-width: 600px;
    min-width: 375px; */
  }
  ${({ isMobile }) =>
    isMobile &&
    css`
      margin-top: 50px;
      img {
        max-width: unset;
      }
    `}
  ${({ postCampaign }) =>
    postCampaign &&
    css`
      margin-top: 0px !important;
      img {
        display: none;
      }
    `}
`;

const OverlayContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const OverlayImage = styled.img`
  width: clamp(50px, 15vw, 100px);
  cursor: pointer;
`;

// ======== MODAL ======== //

// const VideoModal = styled(Modal)`
//   justify-content: center;
//   min-height: unset;
//   position: relative;

//   svg {
//     top: 10px;
//     right: 10px;
//   }
// `;

// const VideoModalContainer = styled.div`
//   padding-top: 56.25%;
//   position: relative;
// `;

// const Video = styled.iframe`
//   height: 100%;
//   position: absolute;
//   top: 0;
//   width: 100%;
//   border: 0;
// `;
